import React, { useEffect, useRef } from 'react';
import './Home.css';

function Home() {
    const aboutSectionRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#about') {
      scrollToAbout();
    }
  }, []);

    const scrollToAbout = () => {
      if (aboutSectionRef.current) {
        aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

  return (
    <div className="home-container">
      {/* 4K Image Section */}
      <div className="hero-image-container">
        <img src="Image_Tazkmazter-1.jpeg" alt="Hero Image" className="hero-image" />
      </div>

        {/* Top Banner */}
        <div className="banner top-banner">
        <div className="banner-content">
          Equip the industries with high-quality test bench and instruments to enhance productivity.
        </div>
      </div>

      {/* About Section */}
      <div ref={aboutSectionRef} id="about-section" className="about-tile">
        <h2>About</h2>
        <p>
          Tazkmazter Private Limited was formed by a group of Engineers by understanding the importance of providing meaningful solutions in Test & Measurement and Control Instrumentation through evaluative market research and customized Industrial requirements.
        </p>
        <p>
          The motivation behind this innovative thinking is to accomplish an ingenious cost-effective and quality solution in stipulated time.
        </p>
        <p>
          Our driving initiative pertains, providing complete instrumentation solutions, high-quality Testing and Calibration Benches, ESD Workstations, Laboratory Tables, Test and Measurement Instruments AC DC Power Supplies Tools and Tackles, and a variety of other products and services.
        </p>
        <p>
          We service a broad range of industries including Defence, Aerospace, Oil and Gas, Petrochemical, Power generation, Shipbuilding, Mineral, Mining, Metallurgy, Food processing, Construction, Education etc.
        </p>
        <p>
          Most importantly, we are a team of highly qualified professionals who pride ourselves on our commitment to providing the highest level of service to our customers.
        </p>
      </div>

            {/* Bottom Banner */}
            <div className="banner bottom-banner">
        <div className="banner-content">
          Hassle-free Testing and Calibration
        </div>
      </div>

      {/* Footer with Social Media Links */}
      <footer>
        <ul className="social-media-links">
          <li><a href="https://facebook.com" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
          <li><a href="https://linkedin.com" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
          <li><a href="https://instagram.com" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
        </ul>
      </footer>
    </div>
  );
}

export default Home;
