import React from 'react';
import './CastingandMachining.css';

function CastingandMachining() {
  const items = [
    { name: 'Transmission Housing Casting', link: '/casting-machining/transmission-housing-casting' },
    { name: 'Axle Housing Casting', link: '/casting-machining/axle-housing-casting' },
    { name: 'Engine Castings', link: '/casting-machining/engine-castings' },
    { name: 'Cylinder Head', link: '/casting-machining/cylinder-head' },
    { name: 'Crankcase', link: '/casting-machining/crankcase' },
    { name: 'Cylinder Block', link: '/casting-machining/cylinder-block' },
    { name: 'Hydraulic Distributor', link: '/casting-machining/hydraulic-distributor' },
    { name: 'Complex Housings', link: '/casting-machining/complex-housings' },
  ];

  return (
    <div className="casting-machining-container">
      <h1>Casting and Machining</h1>
      <div className="tiles-grid">
        {items.map((item, index) => (
          <div key={index} className="tile">
            <a href={item.link}>{item.name}</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CastingandMachining;
