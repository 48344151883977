import React from 'react';

function CylinderBlock  () {
  return (
    <div>
      <h1>CylinderBlock  </h1>
      <p>Details about CylinderBlock  ...</p>
    </div>
  );
}

export default CylinderBlock  ;