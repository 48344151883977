import React from 'react';
import './TestBenches.css'; // Create a new CSS file for styling

function TestBenches() {
  const benches = [
    { name: 'Electrical-Electronic Calibration Bench', link: '/test-benches/electrical-electronic-calibration-bench' },
    { name: 'Pressure-Pneumatic Calibration Bench', link: '/test-benches/pressure-pneumatic-calibration-bench' },
    { name: 'Temperature Calibration Bench', link: '/test-benches/temperature-calibration-bench' },
    { name: 'Electrical Test Benches', link: '/test-benches/electrical-test-benches' },
    { name: 'Electronics Test Benches', link: '/test-benches/electronics-test-benches' },
    { name: 'Instruments Racks', link: '/test-benches/instruments-racks' },
    { name: 'Customize Your Test Bench', link: '/test-benches/customize-your-test-bench' },
  ];

  return (
    <div className="test-benches-container">
      <h1>Test Benches</h1>
      <div className="tiles-grid">
        {benches.map((bench, index) => (
          <div key={index} className="tile">
            <a href={bench.link}>{bench.name}</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestBenches;
