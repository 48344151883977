import React from 'react';
import "./contacts.css"
function ContactUs() {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      
      <h2>Address:</h2>
      <p>
        TMCI Private Limited<br />
        (Tazkmazter Controlled Instrumentation Pvt. Ltd.)<br />
        # 66, Ground Floor, 2nd Cross, Vignana Nagar, New Thippasandra Post,<br />
        L Narayana Reddy Main Road, Near Shell Petrol Pump, Bangalore 560 075, INDIA<br />
      </p>

      <h2>Contact Persons:</h2>

      <h3>Wilfred Vineethkumar</h3>
      <p>
        Designation: Sr. Engineer - Sales & Technical<br />
        Mobile: +91 9742944306<br />
        Mail: <a href="mailto:sales@tazkmazter.com">sales@tazkmazter.com</a><br />
      </p>

      <h3>Satwik S</h3>
      <p>
        Designation: Engineer - Sales & Technical<br />
        Mobile: +91 9440671887<br />
        Mail: <a href="mailto:info@tazkmazter.com">info@tazkmazter.com</a><br />
      </p>

      <h3>Satheesh Sasi</h3>
      <p>
        Designation: Director<br />
        Mobile: +91 9591119333<br />
        Mail: <a href="mailto:satheesh@tazkmazter.com">satheesh@tazkmazter.com</a><br />
      </p>
    </div>
  );
}

export default ContactUs;
