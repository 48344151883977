import React from 'react';
import './otherProducts.css';

const products = [
  'AC / DC High Voltage Breakdown Testers',
  'AC / DC High Voltage Probes',
  'AC / DC Variable Voltage / Current / Frequency Sources',
  'AC High Current Source (2000A)',
  'AC Power Supplies',
  'Bidirectional Power Supplies',
  'Cable Route Locator',
  'Data Acquisition System (Data Loggers)',
  'Decade Boxes or Decade RLC Boxes',
  'Digital Multimeters',
  'Digital Panel Meters',
  'Digital Tachometer Calibrators',
  'Digital UPS Scanners',
  'EC/ESD Testers',
  'Electrical Safety Testers',
  'Energy / Power Meter Calibrators (Three Phase)',
  'Electronic Loads',
  'Function Generators/Arbitrary Waveform Generators',
  'Ground Bond Testers',
  'Ground Continuity Testers',
  'High Frequency Stunner Electronic Load',
  'High Resistance Jigs',
  'Hipot Tester AC/DC/IR',
  'Insulation Resistance Testers',
  'Leakage Current Testers',
  'Linear Transmitter Simulators',
  'LCR Meters',
  'Low Resistance Jigs',
  'mV / mA / RTD Calibrators',
  'Multi-Function Calibrators',
  'Nano Ampere Sources',
  'Optical Fiber Splicing Machines',
  'Optical Power Meters',
  'Optical Power Source',
  'Optical Spectrum Analyzer',
  'Optical Time Domain Reflectometer',
  'Power Energy Meter Calibrators',
  'Power Meter and Power Analyzers',
  'RACK Mount Kits',
  'Secondary Injection Kits',
  'Soldering De Soldering Stations',
  'Signal Analyzers',
  'Standard Resistors',
  'Time Interval Meters',
  'Transistor, Zener Diode Testers',
  'Variable Current / Frequency Source',
];

function OtherProducts() {
    console.log("OtherProducts component is rendering"); // Debugging line
    return (
      <div className="other-products">
        <h1>Expanded Product Line</h1>
        <div className="products-grid">
          {products.map((product, index) => (
            <div key={index} className="product-tile">
              {product}
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default OtherProducts;
