import React from 'react';

function AxleHousingCasting  () {
  return (
    <div>
      <h1>AxleHousingCasting  </h1>
      <p>Details about AxleHousingCasting  ...</p>
    </div>
  );
}

export default AxleHousingCasting  ;