import React from 'react';
import './clients.css';

function Clients() {
  const clients = [
    { name: 'NTPC Limited', logo: '/logo/NTPC.png' },
    { name: 'Indian Space Research Organisation', logo: '/logo/ISRO.png' },
    { name: 'Chennai Metro Rail Limited', logo: '/logo/Chennai Meto.png' },
    { name: 'Oil and Natural Gas Corporation', logo: '/logo/ONGC.png' },
    { name: 'Kerala State Electronics Development Corporation Limited', logo: '/logo/Keltron.png' },
    { name: 'NTPC SAIL Power Company Limited', logo: '/logo/NTPC SAIL Power Company Limited.png' },
    { name: 'KOCHI METRO RAIL LIMITED', logo: '/logo/KOCHI METRO RAIL LIMITED.png' },
    { name: 'Visakha Container Terminal Pvt. Ltd', logo: '/logo/Visakha.jpg' },
    { name: 'Indian Oil Corporation Limited', logo: '/logo/Indian Oil.png' },
    { name: 'NATIONAL INSTITUTE OF TECHNOLOGY', logo: '/logo/National Institute.png' },
    { name: 'STEEL AUTHORITY OF INDIA', logo: '/logo/SAIL.png' },
    { name: 'ONGC', logo: '/logo/ONGC.png' },
    { name: 'SEW-EURODRIVE India Pvt Ltd', logo: '/logo/SEW-EURODRIVE.png' },
    { name: 'Fluke Technologies Pvt. Ltd.', logo: '/logo/FLUKE.png' },
    { name: 'West Coast Power Private Limited', logo: '/logo/West Coast.jpg' },
    { name: 'Yokogawa India Limited', logo: '/logo/Yokogawa India Limited.png' },
    { name: 'DB POWER LIMITED', logo: '/logo/DB POWER LIMITED.png' },
    { name: 'Toyota Accessories Development ME India Private Limited', logo: '/logo/Toyota Accessories Development ME India Private Limited.jpg' },
    { name: 'MicroStep Monitoring Information System India Pvt Ltd', logo: '/logo/MicroStep Monitoring Information System India Pvt Ltd.png' },
    { name: 'Intertek India Pvt Ltd', logo: '/logo/Intertek.jpg' },
    { name: 'Epsilon Carbon Private Limited', logo: '/logo/Epsilon.png' },
    { name: 'Advance Tech Controls Pvt Ltd', logo: '/logo/AdvanceTech.png' },
    { name: 'GRANITE RIVER LABS TECHNOLOGY PVT LTD', logo: '/logo/Granite River labs.png' },
    { name: 'TATA POWER COMPANY LIMITED', logo: '/logo/TATA POWER COMPANY LIMITED.png' },
    { name: 'Saint-Gobain India Private Limited', logo: '/logo/Saint-Gobain.png' },
    { name: 'Murata Electronics (India) Private Limited', logo: '/logo/Murata Electronics (India) Private Limited..png' },
    { name: 'TÜV Rheinland (India) Pvt. Ltd.', logo: '/logo/TÜV Rheinland (India) Pvt. Ltd..png' },
    { name: 'UL India Pvt Ltd', logo: '/logo/UL India.png' },
    { name: 'SIEMENS', logo: '/logo/Siemens.png' },
    { name: 'MACO CORPORATION', logo: '/logo/MACO.png' },
    { name: 'BENGAL ELECTRICAL INDUSTRIES', logo: '/logo/BENGAL ELECTRICAL INDUSTRIES.png' },
    { name: 'Optimized Solutions Limited', logo: '/logo/Optimized Solutions.jpg' },
    { name: 'REVINE TECHNOLOGIES PVT LTD', logo: '/logo/REVINE TECHNOLOGIES PVT LTD.jpg' },
    { name: 'Enconpass Pvt. Ltd.', logo: '/logo/Enconpass Pvt. Ltd..png' },
    { name: 'Embitel Technologies (India) Pvt.Ltd', logo: '/logo/embitel.png' },
    { name: 'AARJAY INTERNATIONAL PRIVATE LIMITED', logo: '/logo/AARJAY.jpg' },
    { name: 'MICROTEK INSTRUMENTS EMC', logo: '/logo/MICROTEK INSTRUMENTS EMC.png' },
    { name: 'SINETEC AUTOMATION PVT.LTD', logo: '/logo/Sinetec.jpg' },
    { name: 'AUTOMATION SYSTEM ENGG. PVT LTD', logo: '/logo/Automation Solutions.jpg' },


    // Add more clients as needed
  ];

  return (
    <div className="clients-container">
      <h1>Our Clients</h1>
      <div className="clients-grid">
        {clients.map((client, index) => (
          <div key={index} className="client-tile">
            <img src={client.logo} alt={client.name} className="client-logo" />
            <p>{client.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clients;
