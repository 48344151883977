import React from 'react';

function ComplexHousings  () {
  return (
    <div>
      <h1>ComplexHousings  </h1>
      <p>Details about ComplexHousings  ...</p>
    </div>
  );
}

export default ComplexHousings  ;