import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Ensure this is imported for FontAwesome icons
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components.js/Home';
import TestBenches from './components.js/TestBenches';
import ESDTables from './components.js/ESDTables'; // Import the new ESDTables component
import LaboratoryTables from './components.js/LaboratoryTables'; // Import the new LaboratoryTables component
import ElectricalElectronicCalibrationBench from './components.js/ElectricalElectronicCalibrationBench';
import PressurePneumaticCalibrationBench from './components.js/PressurePneumaticCalibrationBench';
import TemperatureCalibrationBench from './components.js/TemperatureCalibrationBench';
import ElectricalTestBenches from './components.js/ElectricalTestBenches';
import ElectronicsTestBenches from './components.js/ElectronicsTestBenches';
import InstrumentsRacks from './components.js/InstrumentsRacks';
import CustomizeYourTestBench from './components.js/CustomizeYourTestBench';
import BasicESDTables from './components.js/BasicESDTables';
import HighEndESDTables from './components.js/HighEndESDTables';
import ESDCleanRooms from './components.js/ESDCleanRooms';
import ESDAccessories from './components.js/ESDAccessories';
import BasicTables from './components.js/BasicTables';
import HighEndTables from './components.js/HighEndTables';
import HeavyDutyBenches from './components.js/HeavyDutyBenches';
import OtherProducts from './components.js/OtherProducts';
import ServiceAMC from './components.js/ServiceAMC';
import Partners from './components.js/Partners';
import Clients from './components.js/Clients';
import Certifications from './components.js/Certifications';
import ContactUs from './components.js/ContactUs';
import Software from './components.js/software';
import CastingandMachining from './components.js/CastingandMachining';
import TransmissionHousingCasting from './components.js/TransmissionHousingCasting';
import AxleHousingCasting from './components.js/AxleHousingCasting';
import EngineCastings from './components.js/EngineCastings';
import CylinderHead from './components.js/CylinderHead';
import Crankcase from './components.js/Crankcase';
import CylinderBlock from './components.js/CylinderBlock';
import HydraulicDistributor from './components.js/HydraulicDistributor';
import ComplexHousings from './components.js/ComplexHousings';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul className="menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/test-benches">Test Benches</Link></li>
            <li><Link to="/esd-tables">ESD Tables</Link></li> {/* Link to the new ESD Tables page */}
            <li><Link to="/laboratory-tables">Laboratory Tables</Link></li> {/* Link to the new Laboratory Tables page */}
            <li><Link to="/software">Software</Link></li> 
            <li><Link to="/Casting and Machining">Casting and Machining</Link></li>
            <li><Link to="/other-products">Expanded Product Line</Link></li>
            <li><Link to="/service-amc">Service and AMC</Link></li>
            <li><Link to="/partners">Our Partners</Link></li>
            <li><Link to="/clients">Our Clients</Link></li>
            <li><Link to="/certifications">Certifications</Link></li>
            <li><Link to="/contact-us">Contact Us</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/test-benches" element={<TestBenches />} />
          <Route path="/esd-tables" element={<ESDTables />} /> {/* New ESD Tables route */}
          <Route path="/laboratory-tables" element={<LaboratoryTables />} /> {/* New Laboratory Tables route */}
          <Route path="/test-benches/electrical-electronic-calibration-bench" element={<ElectricalElectronicCalibrationBench />} />
          <Route path="/test-benches/pressure-pneumatic-calibration-bench" element={<PressurePneumaticCalibrationBench />} />
          <Route path="/test-benches/temperature-calibration-bench" element={<TemperatureCalibrationBench />} />
          <Route path="/test-benches/electrical-test-benches" element={<ElectricalTestBenches />} />
          <Route path="/test-benches/electronics-test-benches" element={<ElectronicsTestBenches />} />
          <Route path="/test-benches/instruments-racks" element={<InstrumentsRacks />} />
          <Route path="/test-benches/customize-your-test-bench" element={<CustomizeYourTestBench />} />
          <Route path="/esd-tables/basic-esd-tables" element={<BasicESDTables />} />
          <Route path="/esd-tables/high-end-esd-tables" element={<HighEndESDTables />} />
          <Route path="/esd-tables/esd-clean-rooms" element={<ESDCleanRooms />} />
          <Route path="/esd-tables/esd-accessories" element={<ESDAccessories />} />
          <Route path="/laboratory-tables/basic-tables" element={<BasicTables />} />
          <Route path="/laboratory-tables/high-end-tables" element={<HighEndTables />} />
          <Route path="/laboratory-tables/heavy-duty-benches" element={<HeavyDutyBenches />} />
          <Route path="/other-products" element={<OtherProducts />} />
          <Route path="/software" element={<Software />} />
          <Route path="/Casting and Machining" element={<CastingandMachining />} />
          <Route path="/service-amc" element={<ServiceAMC />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/certifications" element={<Certifications />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/CastingandMachining/transmission-housing-casting" element={<TransmissionHousingCasting />} />
          <Route path="/CastingandMachining/axle-housing-casting" element={<AxleHousingCasting />} />
          <Route path="/CastingandMachining/engine-castings" element={<EngineCastings />} />
          <Route path="/CastingandMachining/cylinder-head" element={<CylinderHead />} />
          <Route path="/CastingandMachining/crankcase" element={<Crankcase />} />
          <Route path="/CastingandMachining/cylinder-block" element={<CylinderBlock />} />
          <Route path="/CastingandMachining/hydraulic-distributor" element={<HydraulicDistributor />} />
          <Route path="/CastingandMachining/complex-housings" element={<ComplexHousings />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
