import React from 'react';
import './LaboratoryTables.css';

function LaboratoryTables() {
  const labTables = [
    { name: 'Basic Tables', link: '/laboratory-tables/basic-tables' },
    { name: 'High-End Tables', link: '/laboratory-tables/high-end-tables' },
    { name: 'Heavy Duty Benches & Tables', link: '/laboratory-tables/heavy-duty-benches' },
  ];

  return (
    <div className="laboratory-tables-container">
      <h1>Laboratory Tables</h1>
      <div className="tiles-grid">
        {labTables.map((table, index) => (
          <div key={index} className="tile">
            <a href={table.link}>{table.name}</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LaboratoryTables;
