import React from 'react';
import './ESDTables.css';

function ESDTables() {
  const esdTables = [
    { name: 'Basic ESD Tables', link: '/esd-tables/basic-esd-tables' },
    { name: 'High-End ESD Tables', link: '/esd-tables/high-end-esd-tables' },
    { name: 'ESD Clean Rooms', link: '/esd-tables/esd-clean-rooms' },
    { name: 'ESD Accessories', link: '/esd-tables/esd-accessories' },
  ];

  return (
    <div className="esd-tables-container">
      <h1>ESD Tables</h1>
      <div className="tiles-grid">
        {esdTables.map((table, index) => (
          <div key={index} className="tile">
            <a href={table.link}>{table.name}</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ESDTables;
