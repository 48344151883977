import React, { useState, useEffect  } from 'react';
import './Certifications.css';


function Certifications() {
  const [zoomedTile, setZoomedTile] = useState(null);
  const certificates = [
    { name: 'ANSI-ESD S20.20-2021', image: '/ceritifcate/ANSI-ESD S20.20-2021.png' },
    { name: 'IEC 61340-5-1 : 2016', image: '/ceritifcate/IEC 61340-5-1-2016.png' },
    { name: 'ISO 14644-1:2015 CLASS 7', image: '/ceritifcate/ISO 14644-1-2015 CLASS 7.png' },
    { name: 'IEC 61000-6-1 : 2016', image: '/ceritifcate/IEC 61000-6-1-2016.png' },
    { name: 'IEC 61000-6-3 : 2020', image: '/ceritifcate/IEC 61000-6-3-2020.png' },
    { name: 'IEC 61000-4-5 : 2005', image: '/ceritifcate/IEC 61000-4-5-2005.png' },
    { name: 'BIFMA', image: '/ceritifcate/BIFMA.png' },
    { name: 'CE', image: '/ceritifcate/CE.png' },
    { name: 'DIN EN 13150:2001', image: '/ceritifcate/DIN EN 13150-2001.png' },
    { name: 'IEC 61643-1 : 2005', image: '/ceritifcate/IEC 61643-1 - 2005.png' },
    { name: 'ISO 9001 : 2015', image: '/ceritifcate/ISO 9001-2015.png' },
    { name: 'ISO 14001 : 2015', image: '/ceritifcate/ISO 14001 -2015.png' },
    { name: 'ISO 45001 : 2018', image: '/ceritifcate/ISO 45001 -2018.png' },
    { name: 'ROHS', image: '/ceritifcate/ROHS.png'},

    // Add more certificates as needed
  ];

  const handleTileClick = (index) => {
    setZoomedTile(index === zoomedTile ? null : index);
  };

  // Handle clicks outside the zoomed tile
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (zoomedTile !== null && !event.target.closest('.certificate-tile')) {
        setZoomedTile(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [zoomedTile]);


  return (
    <div className="certifications-container">
      <h1>Our Certifications</h1>
      <div className="certifications-grid">
        {certificates.map((certificate, index) => (
          <div
            key={index}
            className={`certificate-tile ${zoomedTile === index ? 'zoomed' : ''}`}
            onClick={() => handleTileClick(index)}
          >
            <img src={certificate.image} alt={certificate.name} className="certificate-image" />
            <p>{certificate.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Certifications;
