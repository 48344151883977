import React from 'react';

function EngineCastings () {
  return (
    <div>
      <h1>EngineCastings </h1>
      <p>Details about EngineCastings ...</p>
    </div>
  );
}

export default EngineCastings ;