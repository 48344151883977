import React from 'react';

function Crankcase  () {
  return (
    <div>
      <h1>Crankcase  </h1>
      <p>Details about Crankcase  ...</p>
    </div>
  );
}

export default Crankcase  ;